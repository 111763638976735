import { logMessage, LOG_LEVELS } from './logging.js';

document.addEventListener("DOMContentLoaded", function() {
    try {
        setupMenu();
        logMessage(LOG_LEVELS.INFO, 'Menu setup complete.');
    } catch (error) {
        logMessage(LOG_LEVELS.ERROR, 'Error setting up menu', { error: error.message });
    }
  });

  function setupMenu() {
    var menu = document.getElementById("menuButton") // Assuming it's the first close element
    menu.addEventListener('click', function() {
      toggleMenu();
    });
  }

 function toggleMenu() {
    var navbar = document.querySelector('.navbar');
    
    navbar.style.display = navbar.style.display === "block" ? "none" : "block";
    
}